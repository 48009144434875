<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-modal
            ref="modalRef"
            centered
            cancel-variant="outline-secondary"
            title-tag="div"
            size="lg"
            @hidden="modalRowData = null"
          >
            <template #modal-title>
              Schema Details
            </template>
            <template #default>
              <div>
                <!-- Display row details here -->
              
                <b-row v-if="modalRowData.schemaable_type=='Modules\\Course\\Entities\\Course'">
                  <b-row>

                    <b-col md="6">
                      <b-form-group
                        label="Name"
                        label-for="blog-edit-course"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules=""
                        >
                          <b-form-input
                            id="COURSE-NAME"
                            v-model="modalRowData.name"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Category"
                        label-for="blog-edit-course"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="cate"
                          rules=""
                        >
                          <b-form-input
                            id="COURSE-LOGO"
                            v-model="modalRowData.category"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Location Name"
                        label-for="location-name"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="locationName"
                          rules=""
                        >
                          <b-form-input
                            id="LOCATION-name"
                            v-model="modalRowData.location.name"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Aggregate Rating"
                        label-for="aggregate-rating"
                        class="mb-2"
                      >
                        <b-form-input
                          id="aggregate-rating"
                          v-model="modalRowData.aggregateRating.ratingValue"
                          disabled
                          type="number"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Aggregate Rating Count"
                        label-for="aggregate-rating"
                        class="mb-2"
                      >
                        <b-form-input
                          id="aggregate-rating"
                          v-model="modalRowData.aggregateRating.ratingCount"
                          disabled
                          type="number"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Aggregate Review Count"
                        label-for="aggregate-rating"
                        class="mb-2"
                      >
                        <b-form-input
                          id="aggregate-rating"
                          v-model="modalRowData.aggregateRating.reviewCount"
                          disabled
                          type="number"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Course Information -->

                    <b-col md="6">
                      <b-form-group
                        label="Offer Type"
                        label-for="offer-type"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="offerType"
                          rules=""
                        >
                          <b-form-input
                            id="OFFER-@type"
                            v-model="modalRowData.offers['@type']"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Offer Price"
                        label-for="offer-price"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="offerPrice"
                          rules=""
                        >
                          <b-form-input
                            id="OFFER-price"
                            v-model="modalRowData.offers.price"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Offer Category"
                        label-for="offer-price"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="offerPrice"
                          rules=""
                        >
                          <b-form-input
                            id="OFFER-price"
                            v-model="modalRowData.offers.category"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Has Course Instance -->
                    <b-col md="6">
                      <b-form-group
                        label="provider Type"
                        label-for="course-images"
                        class="mb-2"
                      >
                        Organization
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="provider Name"
                        label-for="course-images"
                        class="mb-2"
                      >
                        <b-form-input
                          id="provider-name"
                          v-model="modalRowData.provider.name"
                          disabled
                        />
                      </b-form-group>
                    </b-col><b-col md="6">
                      <b-form-group
                        label="provider URL"
                        label-for="course-images"
                        class="mb-2"
                      >
                        <b-form-input
                          id="provider-url"
                          v-model="modalRowData.provider.url"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>

                </b-row>
                  <b-row v-if="modalRowData.schemaable_type=='Modules\\DynamicContent\\Entities\\Post'">
              

                    <b-col md="6">
                      <b-form-group
                        label="Headline"
                        label-for="blog-edit-course"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules=""
                        >
                          <b-form-input
                            id="COURSE-NAME"
                            v-model="modalRowData.headline"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Image"
                        label-for="blog-edit-course"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="cate"
                          rules=""
                        >
                          <b-form-input
                            id="COURSE-LOGO"
                            v-model="modalRowData.image"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="date Published"
                        label-for="location-name"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="locationName"
                          rules=""
                        >
                         
                          
                  <flat-pickr
                    v-model="modalRowData.datePublished"
                    class="form-control"
                  />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                          label="date Modified"
                        label-for="aggregate-rating"
                        class="mb-2"
                      >
                        <flat-pickr
                    v-model="modalRowData.dateModified"
                    class="form-control"
                  />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                           label="Type "
                        label-for="aggregate-rating"
                        class="mb-2"
                      >
                        <v-select
            id="LOCATION-type"
            v-model="modalRowData.author['@type']"
               :options="['Orgnization','Person']"
          />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                           label="author "
                        label-for="aggregate-rating"
                        class="mb-2"
                      >
                        <b-form-input
                          id="aggregate-rating"
                          v-model="modalRowData.author.name"
                          disabled
                          type="number"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Course Information -->

                    <b-col md="6">
                      <b-form-group
                    label="Location Name"
                        label-for="offer-type"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="offerType"
                          rules=""
                        >
                          <b-form-input
                            id="OFFER-@type"
                            v-model="modalRowData.author.url"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Offer Price"
                        label-for="offer-price"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="offerPrice"
                          rules=""
                        >
                          <b-form-input
                            id="OFFER-price"
                            v-model="modalRowData.offers.price"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Offer Category"
                        label-for="offer-price"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="offerPrice"
                          rules=""
                        >
                          <b-form-input
                            id="OFFER-price"
                            v-model="modalRowData.offers.category"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Has Course Instance -->
                    

              

                </b-row>
                <b-row v-if="modalRowData.schemaable_type=='Modules\\Event\\Entities\\Event'"> 
             
             
            <b-col md="6">
              <b-form-group
                label="Name"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cate"
                  rules=""
                >
                  <b-form-input
                    id="COURSE-NAME"
                    disabled
                    v-model="modalRowData.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="start Date"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cate"
                  rules=""
                >
                 
                  <flat-pickr
                   v-model="modalRowData.startDate"
                    class="form-control"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
             <b-col md="6">
              <b-form-group
                label="endDate"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cate"
                  rules=""
                >
                  
                  
                  <flat-pickr
                   v-model="modalRowData.endDate"
                    class="form-control"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
             <b-col md="6">
              <b-form-group
                label="eventAttendanceMode"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cate"
                  rules=""
                >
                  <b-form-input
                    id="COURSE-modalRowData"
                    disabled
                    v-model="modalRowData.eventAttendanceMode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
             <b-col md="6">
              <b-form-group
                label="eventStatus"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cate"
                  rules=""
                >
                  <b-form-input
                    id="COURSE-modalRowData"
                    disabled
                    v-model="modalRowData.eventStatus"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
             <b-col md="6">
      <b-form-group label="image" label-for="street-address" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="streetAddress"
          rules=""
        >
          <b-form-input
            id="LOCATION-streetAddress"
            v-model="modalRowData.image"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
     <b-col md="6">
      <b-form-group label="description" label-for="street-address" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="descriptionAddress"
          rules=""
        >
          <b-form-input
            id="LOCATION-streetAddress"
            v-model="modalRowData.description"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
              <b-col md="6">
      <b-form-group
        label="Location Type"
        label-for="location-type"
        class="mb-2"
      >
        <validation-provider
          #default="{ errors }"
          name="locationType"
          rules=""
        >
          <b-form-input
            id="LOCATION-locationType"
            v-model="modalRowData.location['@type']"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group label="Location Name" label-for="location-name" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="locationName"
          rules=""
        >
          <b-form-input
            id="LOCATION-name"
            v-model="modalRowData.location.name"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group label="Street Address" label-for="street-address" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="streetAddress"
          rules=""
        >
          <b-form-input
            id="LOCATION-streetAddress"
            v-model="modalRowData.location.address.streetAddress"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
     <b-col md="6">
      <b-form-group label="Offer Type" label-for="offer-type" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="offerType"
          rules=""
        >
          <b-form-input
            id="OFFER-@type"
            v-model="modalRowData.offers['@type']"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group label="Offer URL" label-for="offer-url" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="offerUrl"
          rules=""
        >
          <b-form-input
            id="OFFER-url"
            v-model="modalRowData.offers.url"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group label="Offer Price" label-for="offer-price" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="offerPrice"
          rules=""
        >
          <b-form-input
            id="OFFER-price"
            v-model="modalRowData.offers.price"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
     <b-col md="6">
      <b-form-group label="Performer Type" label-for="performer-type" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="performerType"
          rules=""
        >
          <b-form-input
            id="PERFORMER-@type"
            v-model="modalRowData.performer['@type']"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
       <b-col md="6">
      <b-form-group label="Performer Name" label-for="performer-name" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="performerName"
          rules=""
        >
          <b-form-input
            id="PERFORMER-name"
            v-model="modalRowData.performer.name"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group label="Organizer Type" label-for="organizer-type" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="organizerType"
          rules=""
        >
          <b-form-input
            id="ORGANIZER-@type"
            v-model="modalRowData.organizer['@type']"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

 <b-col md="6">
      <b-form-group label="Organizer Name" label-for="organizer-name" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="organizerName"
          rules=""
        >
          <b-form-input
            id="ORGANIZER-name"
            v-model="modalRowData.organizer.name"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group label="Organizer URL" label-for="organizer-url" class="mb-2">
        <validation-provider
          #default="{ errors }"
          name="organizerUrl"
          rules=""
        >
          <b-form-input
            id="ORGANIZER-url"
            v-model="modalRowData.organizer.url"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>


       
           </b-row>
              </div>
            </template>
            <template #modal-footer="{ cancel }">
              <!-- Close button -->
              <b-button @click="cancel">
                Close
              </b-button>
            </template>
          </b-modal>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="3">
            <v-select
              v-model="searchQueryTYPE"
              class=" mt-1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[{
                           label: 'General Course', value: 'Course',
                         },
                         {
                           label: 'Post', value: 'Post',
                         },
                         {
                           label: 'Event', value: 'Event',
                         }]"
              placeholder="Search Type..."

              label="label"
              :reduce="(val)=> val.value"
              :clearable="true"
            />

          </b-col>
          <!-- Search -->
          <b-col
            cols="3"
            md="3"
            class="d-flex align-items-end justify-content-end mb-1 mb-md-0"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="PostsList"
        :sort-by.sync="sort"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(schemaable_type)="data">
          <span v-if="data.item.schemaable_type =='Modules\\DynamicContent\\Entities\\Post'">Post</span>
          <span v-else-if="data.item.schemaable_type =='Modules\\Course\\Entities\\Course'">Course</span>
          <span v-else>Event</span>
        </template>

        <!-- Column: Actions -->
        <template
          #cell(actions)="data"
        >
          <a
            role="button"
            class="action"
            @click="viewRowDetails(data.item)"
          >

            <feather-icon icon="EyeIcon" />

          </a>
          <a
            role="button"
            class="action"
            @click="DeleteSchema(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, reactive, computed,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,
flatPickr,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
  },
  setup() {
    const PostsList = ref([])
    const searchQueryTYPE = ref('')
    const TypeList = ref([{
      label: 'General Course', value: 'Course',
    },
    {
      label: 'Post', value: 'Post',
    },
    {
      label: 'Event', value: 'Event',
    }])
    const meta = reactive({})
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(25)
    const totalCategories = ref(0)
    const sort = ref('')
    const SortArray = []
    const searchQuery = ref('')
    const currentPage = ref(1)
    watch([currentPage, perPage,
      searchQueryTYPE,
      sort], () => {
      GetAllPosts()
    })
    const GetAllPosts = () => {
      let data = null

      if (!SortArray.includes(sort.value) && sort.value != '') {
        SortArray.push(sort.value)
        data = SortArray.toString().replace('[', '')
      }

      store
        .dispatch('homepage/AllSchema', {
          sort: data,
          'filter[search]': searchQuery.value,
          'filter[type]': searchQueryTYPE.value,
          per_page: perPage.value,
          page: currentPage.value,

        })
        .then(response => {
          PostsList.value = response.data.data
          totalCategories.value = response.data.meta.total
        })
    }
    const refCourseListTable = ref()
    watch([currentPage, perPage, sort, searchQuery], () => {
      GetAllPosts()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    // }
    GetAllPosts()

    const tableColumns = [
      { key: 'id', lanel: 'ID', sortable: true },
      { key: 'schemaable_type', label: 'schemaable Type', sortable: true },
      { key: 'schemaable.title', label: 'title', sortable: true },

      { key: 'schemaable.name', label: 'name', sortable: true },
      { key: 'actions' },

    ]
    // Ref for modal dialog
    const modalRef = ref(null)
    // Row data for modal
    const modalRowData = ref(null)

    // Function to handle the view action and open modal
    const viewRowDetails = rowData => {
          console.log(rowData)
      if (rowData.data) {
        modalRowData.value = JSON.parse(rowData.data)

        modalRowData.value.schemaable_type = rowData.schemaable_type
        console.log( modalRowData.value)
      }

      modalRef.value.show()
    }

    // Close modal function
    const closeModal = () => {
      modalRef.value.hide()
    }
    const DeleteSchema = id => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('homepage/DeleteSchema', id).then(response => {
            GetAllPosts()
            Vue.swal({
              title: 'Schema Deleted ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    return {
      DeleteSchema,
      modalRef,
      modalRowData,
      viewRowDetails,
      closeModal,
      tableColumns,
      searchQueryTYPE,
      TypeList,
      PostsList,
      searchQuery,

      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      refCourseListTable,
      dataMeta,
      SortArray,
      sort,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.action {
    margin-left: 16px;
    color: #D69900 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
